.valcollaplist__item {
  .icon-status {
    font-size: 20px;
    position: relative;
    right: 86px;
    top: 5px;

    &.check {
      color: $docfieldlist-success-color;
    }
    &.clear {
      color: $docfieldlist-danger-color;
    }
    &.warning {
      color: $docfieldlist-color-highlight;
    }
    &.sync {
      color: $docfieldlist-item-modified;
    }
    &.adjust {
      color: grey;
    }
  }
}

%status-item {
  position: relative;
  padding: 8px 12px;
}

.listcollap {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  transition: opacity 250ms ease;
  position: relative;

  &.is-updating-table,
  &.is-updating-chunk,
  &.is-retrieving-datamodels,
  &.is-loading-chunks {
    .listcollap__loader {
      display: block;
    }
  }

  &.is-updating-table,
  &.is-updating-chunk {
    opacity: 0.6;
    pointer-events: none !important;
  }

  &.is-selecting-chunk {
    .listcollap__head {
      pointer-events: none;
    }
    .listcollap__subhead {
      pointer-events: none;
    }
    .listcollap__subbody tbody tr {
      pointer-events: none;
      &.is-selecting-field-chunk {
        pointer-events: all;
        outline-color: $docfieldlist-color-highlight;
        .icon-pointer {
          color: $docfieldlist-color-highlight;
        }
      }
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    display: none;
    pointer-events: none !important;
    background-size: 260px 5px;
    background-color: #8b8b8c;
    background-image: linear-gradient(
      -18deg,
      transparent 0%,
      transparent 8%,
      $docfieldlist-color-highlight 60%,
      $docfieldlist-color-highlight 64%,
      transparent 86%
    );
    transition: height 200ms ease;
    animation: bar-loading 0.5s linear infinite;
  }

  &__empty {
    text-align: center;
    font-size: 12px;
    padding: 10px 0;
  }

  &__validations-header {
    background: $docfieldlist-background-color;
    color: $docfieldlist-color-light;
    padding: 4px 12px;
    font-size: 12px;
    line-height: 1.33;
    outline: 1px solid transparent;
    transition: outline 250ms ease;
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    p {
      margin: 5px 0 5px;
      font-size: 11px;
    }
  }

  &__version {
    background: $docfieldlist-background-color;
    color: $docfieldlist-color-light;
    padding: 4px 12px;
    font-size: 12px;
    line-height: 1.33;
    outline: 1px solid transparent;
    transition: outline 250ms ease;
    display: flex;
  }

  /* Start First Level (Group) */
  &__head {
    display: flex;
    flex-wrap: wrap;
    background: $docfieldlist-group-background-color;
    color: $docfieldlist-color-light;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.33;
    outline: 1px solid transparent;
    transition: outline 250ms ease;
    &:hover {
      outline-color: $docfieldlist-color-highlight;
      > .listcollap__headicon {
        visibility: visible;
      }
    }
  }
  &__title {
    cursor: pointer;
    flex: 1 1 auto;
    padding: 2px 0;
    &::selection {
      background: transparent;
      color: inherit;
    }
    &:before {
      content: '';
      display: inline-block;
      border: 5px solid transparent;
      border-top: 6px solid $docfieldlist-color-light;
      margin-right: 4px;
      vertical-align: top;
      margin-top: 6px;
    }
    &.is-collapsed:before {
      border-left: 7px solid $docfieldlist-color-light;
      margin-top: 4px;
      border-top: 5px solid transparent;
    }
  }

  &__headicon {
    flex: 0 0 auto;
    font-size: 12px;
    line-height: 1;
    margin: 0;
    color: $docfieldlist-icon-color;
    background-color: transparent;
    border: none;
    padding: 0 5px;
    &--confirm {
      color: $docfieldlist-success-color;
    }
    &--cancel {
      color: $docfieldlist-danger-color;
    }
    /* visibility: hidden; */
    &:not(.listcollap__headicon--disabled):hover {
      color: $docfieldlist-color-highlight;
    }
    &:before {
      font: normal normal normal 12px/1 FontAwesome;
      color: inherit;
    }
    &--add:before {
      content: '\f067';
    }
    &--delete:before {
      content: '\f1f8';
    }
    &--edit:before {
      content: '\f040';
    }
    &--trash:before {
      content: '\f1f8';
    }
    &--send:before {
      content: '\f064';
    }
    &--resend:before {
      content: '\f1d8';
    }
    &--editall:before {
      content: '\f044';
    }
    &--confirm {
      visibility: visible !important;
      &:before {
        content: '\f00c';
      }
    }
    &--cancel {
      visibility: visible !important;
      &:before {
        content: '\f00d';
      }
    }
    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &__inputadd {
    flex: 0 0 46px;
    max-width: 80px;
    height: 22px;
    margin-right: 4px;
    background: transparent;
    border: 1px solid $docfieldlist-color-light;
    color: $docfieldlist-color-light;
    text-align: right;
  }
  &__body {
    &.is-collapsed {
      display: none;
    }
  }

  /* Start Second Level (Row) */
  &__subgroup {
    margin-bottom: 1px;
    font-size: 12px;
    &--empty {
      .listcollap__subhead {
        color: #212529;
        cursor: default;
        background-color: #f8f9fa;
        &:hover {
          outline-color: transparent;
        }
      }
    }
  }

  &__subhead {
    display: flex;
    background: $docfieldlist-row-background-color;
    color: $docfieldlist-color-light;
    padding: 6px 12px 6px 28px;
    line-height: 1.33;
    cursor: pointer;
    outline: 1px solid transparent;
    transition: outline 250ms ease;
    &:hover {
      outline-color: white !important;
      > .listcollap__headicon {
        visibility: visible;
      }
    }
    &.is-hidden {
      display: none;
    }

    .icon-extracted-status {
      &--failure {
        color: #da4e4e;
        font-size: 16px;
        left: 3px;
        position: relative;
        top: 3px;
      }
      &:before {
        content: '\f111';
        font: normal normal normal 10px/1 FontAwesome;
        color: inherit;
        padding-left: 10px;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
  &__subtitle {
    font-size: 12px;
    padding: 2px 0;
    flex: 1 1 auto;
    &::selection {
      background: transparent;
      color: inherit;
    }
    &:before {
      content: '';
      display: inline-block;
      border: 5px solid transparent;
      border-top: 6px solid $docfieldlist-border-color;
      margin-right: 4px;
      vertical-align: top;
      margin-top: 4px;
    }
    &.is-collapsed:before {
      border: 5px solid transparent;
      border-top-color: transparent;
      border-left: 7px solid $docfieldlist-border-color;
      margin-top: 2px;
    }
  }
  &__subbody {
    &.is-collapsed {
      display: none;
    }
    &-head {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #72727a;
      > div {
        white-space: pre;
        flex: 0 0 auto;
        padding: 4px 4px;
      }
    }

    /* Start Third Level (Field) */
    &-row {
      display: flex;
      outline: 1px solid transparent;
      transition: outline 250ms ease;
      flex-wrap: wrap;
      .validated-values {
        color: $docfieldlist-color-validated;
      }
      .item-editable .item-editable__value button {
        opacity: 1;
      }
      &:hover {
        background-color: $docfieldlist-color-row-hover;
        outline-color: $docfieldlist-color-outline;
      }

      > div {
        flex: 0 0 auto;
        padding: 4px 4px;
      }
    }

    .astherisk-field {
      color: #da4e4e;
      cursor: default;
    }

    .status-column {
      .status-icon {
        font-size: 16px;
      }
    }
    .fa-exclamation-triangle {
      color: #ad9d0a;
      font-size: 14px;
      padding: 0 5px;
    }

    .item-size-big {
      flex-basis: 24%;
      flex-grow: 10;
    }

    .item-size-normal {
      flex-basis: 20%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .item-size-small {
      flex-basis: 10%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .item-centered {
      text-align: center;
    }

    .item-right {
      text-align: right;
    }

    .item-left {
      text-align: left;
    }

    .icon-pointer {
      cursor: pointer;
      &:not(.icon-pointer--disabled):hover {
        color: $docfieldlist-color-highlight;
      }
      &:before {
        content: '\f05b';
        font: normal normal normal 14px/1 FontAwesome;
        color: inherit;
      }
      &--disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    .icon-cancel {
      cursor: pointer;
      &:hover {
        color: $docfieldlist-color-highlight;
      }
      &:before {
        content: '\f05e';
        font: normal normal normal 14px/1 FontAwesome;
        color: inherit;
      }
    }
    .icon-extracted-status {
      color: grey;
      &--undefined {
        color: grey;
      }
      &--success,
      &--manual-success {
        cursor: pointer;
        color: #47b04d;
      }
      &--failure,
      &--manual-failure {
        cursor: pointer;
        color: #da4e4e;
      }
      &:before {
        content: '\f111';
        font: normal normal normal 10px/1 FontAwesome;
        color: inherit;
        display: inline-block;
        vertical-align: top;
      }
    }
    .responsive-title {
      &__info {
        display: block;
        cursor: pointer;
      }
    }
    .item-editable {
      &__value {
        display: block;
        &.is-modified {
          color:#315a33;
        }
        &--disabled {
          cursor: not-allowed;
        }

        button {
          border: 0;
          padding: 0 0 0 3px;
          background: transparent;
          opacity: 0;
          transition: opacity 250ms ease;
          //color: $docfieldlist-color-light;
          color: rgba(0, 71, 187, 1);
          &:before {
            content: '\f044';
            font: normal normal normal 13px/1 FontAwesome;
            //color: inherit;a
            color: rgba(0, 71, 187, 1);
          }
          &:not(.item-editable__value--disabled):hover {
            //color: $docfieldlist-color-highlight;
            color: rgba(0, 71, 187, 1);
          }
        }
      }
      &__input {
        display: none;
        position: relative;
        width: 100%;
        textarea {
          background: $docfieldlist-input-background-color;
          border: 1px solid $docsfieldlist-color-editarea;
          border-radius: 1px;
          color: #315a33;
          height: 100px;
          line-height: 1.1;
          padding: 5px;
          resize: vertical;
          width: 100%;

          &.is-modified {
            color:#315a33;
          }
          &.is-current-modified {
            color: #315a33;
          }
        }
      }
      &__button {
        position: absolute;
        background: transparent;
        border: 0;
        cursor: pointer;
        transition: color 250ms ease;
        &:hover {
          color: $docfieldlist-color-highlight;
        }
        &:before {
          position: absolute;
          top: 9px;
          line-height: 0;
          font-family: FontAwesome;
          font-size: 16px;
          text-rendering: auto;
        }
        &--save {
          color: $docfieldlist-success-color;
          right: 35px;
          top: -5px;
          &:before {
            content: '\f00c';
          }
        }
        &--cancel {
          color: $docfieldlist-danger-color;
          right: 15px;
          top: -5px;
          &:before {
            content: '\f00d';
          }
        }
      }
      &.is-editing,
      &.is-batch-editing {
        .item-editable__value {
          display: none;
        }
        .item-editable__input {
          display: inline-block;
        }
      }
      &.is-batch-editing {
        .item-editable__button {
          display: none;
        }
      }
    }
  }

  /* Start execution block */
  &__exerow {
    display: none;
    flex: 1 1 100% !important;
    &.is-expanded {
      display: block;
    }
  }
}

.select-editor {
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}

.select-button {
  display: inline !important;
  position: initial !important;
  margin-left: 3px;
  margin-right: 5px;
}

.hidden-row {
  display: none;
}

@media (max-width: 767px) {
  .listcollap__subbody-row.is-editing {
    .item-hide-mobile-editing {
      display: none !important;
    }
  }
}

@media (min-width: 768px) {
  .listcollap {
    &__headicon {
      visibility: hidden;
    }
    &__subbody {
      &-row {
        .item-editable .item-editable__value button {
          opacity: 0;
        }
        &:hover {
          .item-editable .item-editable__value button {
            opacity: 1;
          }
          .item-editable__value--disabled {
            opacity: 0.5 !important;
          }
        }
      }
    }
  }
}

@keyframes bar-loading {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -260px 0;
  }
}
